<template>
    <div>
        <v-app-bar flat color="transparent" class="main-appbar">
            <app-bar-nav-icon />

            <v-skeleton-loader
                type="chip"
                transition="fade-transition"
                v-if="loading"
            ></v-skeleton-loader>
            <v-toolbar-title v-else class="headline font-weight-bold"
                >Reports ({{ reports.length }})</v-toolbar-title
            >

            <v-spacer></v-spacer>
            <v-row v-if="showSearch" align="center" style="max-width: 300px;">
                <v-text-field
                    flat
                    hide-details
                    solo
                    rounded
                    clearable
                    background-color="grey lighten-3"
                    label="Search name"
                    v-model="filter.search"
                    @input="search"
                ></v-text-field>
            </v-row>

            <v-btn class="ml-5" icon @click="showSearch = !showSearch">
                <img src="@/assets/icons/search.svg" alt height="17.7px" />
            </v-btn>
        </v-app-bar>

        <div
            class="mt-12 mx-4"
            id="report-list"
            v-infinite-scroll="loadMore"
            :infinite-scroll-disabled="loading"
            infinite-scroll-distance="100"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th width="15%" class="text-left pa-0">Reported by</th>
                        <th width="15%" class="text-left">Reported User</th>
                        <th width="20%" class="text-left">Reason</th>
                        <th width="30%" class="text-left">Description</th>
                        <th width="20%" class="text-left">Added date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="report in reports" :key="report.id">
                        <td class="py-5">{{ report.reported.full_name }}</td>
                        <td class="py-5">{{ report.reporter.full_name }}</td>
                        <td class="py-5">{{ report.reason.label }}</td>
                        <td class="py-5">{{ report.description }}</td>
                        <td class="py-5">{{ report.addedDate }}</td>
                    </tr>

                    <template v-if="loading">
                        <tr v-for="index in 10" :key="index + '-skeleton'">
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                            <td class="pl-0">
                                <v-skeleton-loader
                                    type="list-item"
                                    transition="fade-transition"
                                />
                            </td>
                        </tr>
                    </template>
                </tbody>
            </v-simple-table>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
    mdiDotsVertical,
    mdiUnfoldMoreHorizontal,
    mdiMagnify,
    mdiAccountCircleOutline,
    mdiChevronRight,
    mdiFileExcel
} from '@mdi/js'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'

export default {
    name: 'ReportsPage',

    mixins: [ControlsMixin],

    directives: {
        infiniteScroll
    },

    components: {
        AppBarNavIcon
    },

    data() {
        return {
            loading: false,
            showSearch: false,
            filter: {
                search: ''
            },
            icons: {
                settings: mdiDotsVertical,
                sort: mdiUnfoldMoreHorizontal,
                search: mdiMagnify,
                view: mdiAccountCircleOutline,
                arrow: mdiChevronRight,
                export: mdiFileExcel
            }
        }
    },

    created() {
        this.clearReport()
        this.fetchReports(1)
    },

    computed: {
        ...mapState({
            reports: state => state.report.list,
            listMeta: state => state.report.listMeta
        }),

        paginationLength() {
            return (this.listMeta.total / this.listMeta.per_page) | 0
        }
    },

    methods: {
        ...mapActions({
            getReports: 'report/getReports'
        }),

        ...mapMutations({
            clearReport: 'report/clearReportsList'
        }),

        pageChanged(page) {
            this.fetchReports(page)
        },

        loadMore() {
            if (this.listMeta.current_page < this.listMeta.last_page) {
                this.fetchReports(this.listMeta.current_page + 1)
            }
        },

        search: debounce(function() {
            this.clearReport()
            this.fetchReports()
        }, 600),

        async fetchReports(page = 1) {
            if (this.loading) return
            let params = { page }
            if (this.filter.search) {
                params.search = this.filter.search
            }
            this.loading = true
            await this.getReports(params)
            this.loading = false
        }
    }
}
</script>
